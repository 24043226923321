.fui-row {
	flex-wrap: wrap;
}

.fui-layout-horizontal .fui-layout-wrap,
.fui-row {
	display: flex;
}

.fui-label {
	@apply sr-only;
}

.fui-field {
	flex: 1;
}

.fui-field,
.fui-btn-container {
	padding: theme("spacing.0") theme("spacing.2") theme("spacing.4")
		theme("spacing.2");
}

.fui-input,
.fui-input-wrap {
	position: relative;
	width: theme("width.full");
	color: theme("colors.black");
	border: theme("borderColor.black") theme("borderWidth.2") solid;
	border-radius: theme("borderRadius.full");
	background: transparent;
	padding: theme("spacing.1") theme("spacing.6");
	transition: all 200ms;
}

textarea.fui-input {
	border-radius: theme("borderRadius.2xl");
}

.fui-input-wrap > select {
	background: transparent;
	width: theme("width.full");
}

.fui-input-wrap > select:focus {
	outline: none;
}

.dual-form-blocks .fui-btn-container .btn {
	display: block;
	margin: 0 auto;
	width: auto;
}

.fui-input:focus {
	outline: none;
	border-color: theme("colors.green.500");
}

.fui-input::placeholder {
	color: theme("colors.gray.600");
}
