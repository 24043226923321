/* From here you can import components or just start writing postCSS */
html {
	font-size: 18px;
}

body {
	@apply antialiased subpixel-antialiased;
	background: theme("colors.black");
	color: theme("colors.white");
	font-family: theme("fontFamily.body");
	font-weight: 400;
}

.video-embed-wrap {
	position: relative;
	background: theme("colors.gray.200");
	&::before {
		content: "";
		display: block;
		position: relative;
		width: 100%;
		height: 0;
		padding-top: calc((9 / 16) * 100%);
	}
}

.video-embed-wrap iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.bg-blur {
	backdrop-filter: blur(5px);
}

.pagination-wrap .active-number {
	position: relative;
	color: theme("colors.gray.100");
}
.pagination-wrap .active-number::before {
	content: "";
	width: 24px;
	height: 24px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: theme("colors.gray.800");
	border-radius: 99999px;
	z-index: -1;
}

.turbolinks-progress-bar {
	height: 6px;
	background-color: theme("colors.green.500");
}

/* School Name Tag */
.school-name-tag {
	position: absolute;
	top: 43%;
	left: 50%;
	text-align: center;
	color: theme("colors.white");
	transform: translate(-50%, -50%);
	max-width: 165px;
	line-height: 1;

	h2 {
		font-size: 12px;
	}
}

@screen md {
	.school-name-tag {
		top: 42%;
		max-width: 185px;
		h2 {
			font-size: 15px;
		}
	}
}

/* Breakpoints */
@screen lg {
	.school-name-tag {
		max-width: 200px;
		h2 {
			font-size: 18px;
		}
	}
	.columns-2 {
		column-count: 2;
		column-gap: 1.5rem;
	}
}

@screen xl {
	.school-name-tag {
		top: 40.8%;
		max-width: 300px;
		h2 {
			font-size: 27px;
		}
	}
}

/* Loading Animation */
#loader {
	opacity: 1;
}
.loading-animation {
	animation: loader 2000ms ease-in-out infinite both;
}

@keyframes loader {
	0% {
		opacity: 0.2;
	}
	50% {
		opacity: 0.7;
	}
	100% {
		opacity: 0.2;
	}
}
