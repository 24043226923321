.btn {
	display: inline-block;
	border: theme("borderColor.transparent") theme("borderWidth.2") solid;
	border-radius: theme("borderRadius.full");
	min-width: 150px;
	font-family: theme("fontFamily.slab");
	padding: theme("spacing.1") theme("spacing.6");
	text-align: center;
	background: transparent;
	transition: all 200ms;

	&--sm {
		min-width: auto;
		padding: theme("spacing.1") theme("spacing.3");
	}

	&--red {
		border: theme("borderColor.red.500") theme("borderWidth.2") solid;
	}

	&--black {
		border: theme("borderColor.black") theme("borderWidth.2") solid;
	}

	&--solid-black {
		border: theme("borderColor.black") theme("borderWidth.2") solid;
		color: theme("colors.white");
		background: theme("backgroundColor.black");
	}
}

.btn:focus {
	outline: none;
}

.btn--red:hover {
	color: theme("colors.white");
	background: theme("backgroundColor.red.500");
}

.btn--black:hover {
	color: theme("colors.white");
	background: theme("backgroundColor.black");
}

.btn--solid-black:hover {
	box-shadow: theme("boxShadow.md");
}
