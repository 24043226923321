/* Fonts.com Sources -------------- */
@font-face {
	font-family: "Avenir LT W01_55 Roman1475520";
	src: url("../fonts/b290e775-e0f9-4980-914b-a4c32a5e3e36.woff2")
			format("woff2"),
		url("../fonts/4b978f72-bb48-46c3-909a-2a8cd2f8819c.woff") format("woff");
}
@font-face {
	font-family: "Trade Gothic";
	src: url("../fonts/TradeGothicNextRust-Heavy.woff2") format("woff2"),
		url("../fonts/TradeGothicNextRust-Heavy.woff") format("woff");
}

/* Google Fonts --------------- */
@font-face {
	font-family: "Arvo";
	src: "../fonts/Arvo-Regular.ttf";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Arvo";
	src: "../fonts/Arvo-Italic.ttf";
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: "Arvo";
	src: "../fonts/Arvo-Bold.ttf";
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Arvo";
	src: "../fonts/Arvo-BoldItalic.ttf";
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Open Sans";
	src: "../fonts/OpenSans-Light.ttf";
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Open Sans";
	src: "../fonts/OpenSans-LightItalic.ttf";
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: "Open Sans";
	src: "../fonts/OpenSans-Regular.ttf";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Open Sans";
	src: "../fonts/OpenSans-Italic.ttf";
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: "Open Sans";
	src: "../fonts/OpenSans-SemiBold.ttf";
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Open Sans";
	src: "../fonts/OpenSans-SemiBoldItalic.ttf";
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: "Open Sans";
	src: "../fonts/OpenSans-Bold.ttf";
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Open Sans";
	src: "../fonts/OpenSans-BoldItalic.ttf";
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: "Open Sans";
	src: "../fonts/OpenSans-ExtraBold.ttf";
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Open Sans";
	src: "../fonts/OpenSans-ExtraBoldItalic.ttf";
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}
